<!-- content starts-->
<div class="content-wrapper theme-font-size">
  <div class="row1 p-r-0">
    <div class=" h-25 p-r-0">
      <div class="default-according" id="accordion">
        <div class="card shadow mb-3">
          <div class="tab-content card-body border-0 p-t-30 p-b-35" id="pills-tabContent" *ngIf="headerTitleService.PermissionVM.viewApplicantInformation">
            <div class="tab-pane fade show active" id="app-info" role="tabpanel" aria-labelledby="pills-home-tab">
              <form (ngSubmit)="allowEdit()" class="needs-validation" ngNativeValidate>
                <div class="row p-l-20">
                  <div class="row col-xl-12 p-l-0">
                    <span class="subhead-blue f-14 roboto-font font-weight-bold col-xl-12 p-r-0">
                      Program Information
                      <hr class="mt-1">
                    </span>
                  </div>
                  <div class="col-xl-12 px-0">
                    <div class=" row col-md-11 px-0">
                      <div class="col-xl-12 mt-3">
  
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="">
                              Date of Engagement:<span class="text-danger">*</span></label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" id="validationCustom4" type="date"
                              required="true" autocomplete="off"
                              (ngModelChange)="applicantService.programInfoModel.doEngagement=$event"
                              name="applicantService.programInfoModel.doEngagement"
                              [ngModel]="applicantService.programInfoModel.doEngagement |  date:'yyyy-MM-dd'"
                              [disabled]="notEditable">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="">
                              GER ID Number:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" style="text-transform:uppercase;"
                              id="gerApplicationNumber" type="text" name="gerApplicationNumber"
                              [(ngModel)]="applicantService.programInfoModel.gerApplicationNumber" required=""
                              data-bs-original-title="" title="" [disabled]="notEditable"
                              (keydown)="onKeydown($event,'#currentVisasId')">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="">
                              Current Visa:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="currentVisasId"
                              name="currentVisasId" [(ngModel)]="applicantService.programInfoModel.currentVisasId"
                              required="" [disabled]="notEditable" (keydown)="onKeydown($event,'#visaProgramsId')">
                              <option class="text-muted" *ngFor="let option of mastersVM?.masterCurrentVisa?.masterValues"
                                [value]="option.id">{{option.name}}</option>
                            </select>
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label subhead-blue  col-xl-12 px-0 m-b-0 f-14 roboto-font" for="">
                              Visa Program:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="visaProgramsId"
                              name="visaProgramsId" [(ngModel)]="applicantService.programInfoModel.visaProgramsId"
                              required="" [disabled]="notEditable" (keydown)="onKeydown($event,'#agencysId')">
                              <option class="text-muted" *ngFor="let option of mastersVM?.masterVisaProgram?.masterValues"
                                [value]="option.id">{{option.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row g-3 my-2">
                          <div class="col-md-3 mt-2">
                            <label class="form-label subhead-blue  col-xl-12 px-0 m-b-0 f-14 roboto-font" for="">
                              Agency:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="agencysId"
                              name="agencysId"
                              [(ngModel)]="applicantService.programInfoModel.agencysId" required=""
                              [disabled]="notEditable" (keydown)="onKeydown($event,'#sponsorsId')">
                              <option class="text-muted" *ngFor="let option of mastersVM?.masterAgency?.masterValues"
                                [value]="option.id">{{option.name}}</option>
                            </select>
                          </div>
                          <div class="col-md-3 mt-2 ">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="sponsorsId">
                              Sponsor:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="sponsorsId"
                              name="sponsorsId" [(ngModel)]="applicantService.programInfoModel.sponsorsId" required=""
                              [disabled]="notEditable || applicantService.programInfoModel.disableSponsor" (keydown)="onKeydown($event,'#jobPositionId')">
                              <option class="text-muted" *ngFor="let option of mastersVM?.masterSponsor?.masterValues"
                                [value]="option.id">{{option.name}}</option>
                            </select>
                          </div>
                          <div class="col-md-3 mt-2 ">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font"
                              for="jobPositionId">
                              Job Position
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="jobPositionId"
                              name="jobPositionId" [(ngModel)]="applicantService.programInfoModel.jobPositionId" required=""
                              [disabled]="notEditable" (keydown)="onKeydown($event,'#attorneysId')">
                              <option class="text-muted" *ngFor="let option of mastersVM?.masterJobPosition?.masterValues"
                                [value]="option.id">{{option.name}}</option>
                            </select>
                          </div>

                          <div class="col-md-3 mt-2 ">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font"
                              for="attorneysId">
                              Attorney:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="attorneysId"
                              name="attorneysId" [(ngModel)]="applicantService.programInfoModel.attorneysId" required=""
                              [disabled]="notEditable" (keydown)="onKeydown($event,'#advisorsId')">
                              <option class="text-muted" *ngFor="let option of mastersVM?.masterAttorney?.masterValues"
                                [value]="option.id">{{option.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row g-3 my-2">
                          <div class="col-md-3 mt-2">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="advisorsId">
                              Advisor:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="advisorsId"
                              name="advisorsId" [(ngModel)]="applicantService.programInfoModel.advisorsId" required=""
                              [disabled]="notEditable"
                              (keydown)="onKeydown($event,'#sponsorLiaisonsId')">
                              <option class="text-muted" *ngFor="let option of masterAdvisorsList" [value]="option.id">
                                {{option.name}}</option>
                            </select>
                          </div>

                          <div class="col-md-3 mt-2">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="">
                              Sponsor Liaison:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="sponsorLiaisonsId"
                              name="sponsorLiaisonsId" [(ngModel)]="applicantService.programInfoModel.sponsorLiaisonsId"
                              required="" [disabled]="notEditable">
                              <option class="text-muted"
                                *ngFor="let option of mastersVM?.masterSponsorLiaison?.masterValues" [value]="option.id">
                                {{option.name}}</option>
                            </select>
                          </div>
                          <!-- <div class="col-md-3 mt-2 ">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="">
                              Adjustment / Consular Process:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="consulateProcessId"
                              name="consulateProcessId" [(ngModel)]="applicantService.programInfoModel.consulateProcessId"
                              required="" [disabled]="notEditable">
                              <option class="text-muted" *ngFor="let list of list1"
                                  [value]="list">
                                  {{Consular1[list]}}
                                  </option>
                              <option *ngFor="let item of consular | populateEnum" [value]="item.key">
                                {{item.value}}
                              </option>
                            </select>
                          </div> -->
                          <div class="col-md-3 mt-2 ">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="">
                              Level:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="levelId" name="levelId"
                              [(ngModel)]="applicantService.programInfoModel.levelId" required=""
                              [disabled]="notEditable">
                              <option class="text-muted" *ngFor="let option of mastersVM?.masterLevel?.masterValues"
                                [value]="option.id">{{option.name}}</option>
                            </select>
                          </div>
                          <div class="col-md-3 mt-2">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="">
                              Applicant Number:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" id="applicationNumber" type="text"
                              name="applicationNumber" [(ngModel)]="applicantService.programInfoModel.applicationNumber"
                              required="" onlyNumbers data-bs-original-title="" title="" [disabled]="notEditable"
                              (keydown)="onKeydown($event,'#currentVisasId')" readonly>
                          </div>
                        </div>

                        <div class="row g-3 my-2">
                          <div class="col-md-3 mt-2">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="">
                              Date Job Letter Sent:<span class="text-danger">*</span></label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" id="validationCustom4" type="date"
                            autocomplete="off"
                              (ngModelChange)="applicantService.programInfoModel.doJobLetterSent=$event"
                              name="applicantService.programInfoModel.doJobLetterSent"
                              [ngModel]="applicantService.programInfoModel.doJobLetterSent |  date:'yyyy-MM-dd'"
                              [disabled]="notEditable">
                          </div>

                          <div class="col-md-3 mt-2">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="">
                              Tandem Program:
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" id="tandemProgramId"
                              name="tandemProgramId" [(ngModel)]="applicantService.programInfoModel.tandemProgramId"
                              required="" [disabled]="notEditable">
                              <option class="text-muted"
                                *ngFor="let option of mastersVM?.masterTandemProgram?.masterValues" [value]="option.id">
                                {{option.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-edit btn-position" *ngIf="showEditBtn" type="submit"
                      [disabled]="isSaving">
                      <i class="fa mr-1"
                        [ngClass]="isSaving?'fa-spin fa-spinner':(notEditable?'fa-edit':'fa-floppy-o')"></i>
                      {{isSaving?'Saving...':(notEditable?'Edit':'Update')}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <!-- Family information -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content Ends-->