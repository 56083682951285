export class Login {
}

export class LoginRequest {
    email: string;
    password: string;
    rememberMe :boolean= false;
    deviceToken: string;
}

export class LoginResponse {
    accessToken: string;
    scope: string;
    tokenType: string;
    expiresIn: number;
    user?: any|null;
    permissionVM?: any|null;

    rememberMeOpted: boolean;
    requiresTwoFactor: boolean;
    twoFactorReason: string;
    login2FAToken: string;
    otpToken: string;

    requiresPasswordReset: boolean;
    resetPasswordToken: string;
}
export class LoginErrorResponse {
    isLocked: boolean;
    unlocksAt: string;
    attemptRemaining: number;
}
export class Login2FAVerifyOTPRequest {
    email: string;
    otpToken: string;
    login2FAToken: string;
    otp: string;
}


export class ResetPasswordRequest {
    email: string;
    newPassword: string;
    confirmPassword: string;
    resetPasswordToken: string;
}
export class ForgotPasswordResetPasswordResponse {

}
export class ForgotPasswordSendOTPRequest {
    email: string;
}
export class ForgotPasswordSendOTPResponse {
    otpToken: string;
    allowedForgotPassword: boolean;
}
export class ForgotPasswordVerifyOTPRequest {
    email: string;
    otpToken: string;
    otp: string;
}
export class ForgotPasswordVerifyOTPResponse {
    resetPasswordToken: string;
}

export class ResendOTPRequest {
    otpToken: string;
}
export class ResendOTPResponse {
    otpToken: string;
    resendLimitReached: boolean;
    resendUnlocksAt: string | null;
}

export class LoginPreference{
    email: string;
    timezoneOffset: number;
    createdAt: string;
}