import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { LoginService } from './login.service';
import {
  ForgotPasswordSendOTPRequest,
  ForgotPasswordVerifyOTPRequest,
  ResetPasswordRequest,
  ForgotPasswordSendOTPResponse,
  ForgotPasswordVerifyOTPResponse,
  ForgotPasswordResetPasswordResponse,
  LoginRequest,
  LoginResponse,
  LoginErrorResponse,
  Login2FAVerifyOTPRequest,
  ResendOTPRequest,
  ResendOTPResponse,
  LoginPreference
} from './login.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService } from '@services/header-title.service';

enum FormType {
  Login = 1,
  ForgotPassword_SendOTP = 2,
  ForgotPassword_VerifyOTP = 3,
  ForgotPassword_ResetPassword = 4,
  TwoFactorOTPRequired = 5,
  ResetPasswordRequired = 6,
  InvalidLink = 7
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  formType: typeof FormType = FormType;
  formId: number = this.formType.Login;

  errorMessage: string = null;
  processing: boolean = false;

  backspacePressed: boolean = false;

  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;

  loginRequest: LoginRequest = new LoginRequest();
  sendOTPRequest: ForgotPasswordSendOTPRequest = new ForgotPasswordSendOTPRequest();
  verifyOTPRequest: ForgotPasswordVerifyOTPRequest = new ForgotPasswordVerifyOTPRequest();
  resetPasswordRequest: ResetPasswordRequest = new ResetPasswordRequest();
  login2FAVerifyOTPRequest: Login2FAVerifyOTPRequest = new Login2FAVerifyOTPRequest();  
  resendOTPRequest: ResendOTPRequest = new ResendOTPRequest();

  loginResponse: LoginResponse | LoginErrorResponse = null;
  sendOTPResponse: ForgotPasswordSendOTPResponse = new ForgotPasswordSendOTPResponse();
  verifyOTPResponse: ForgotPasswordVerifyOTPResponse = new ForgotPasswordVerifyOTPResponse();
  resetPasswordResponse: ForgotPasswordResetPasswordResponse = new ForgotPasswordResetPasswordResponse();
  resendOTPResponse: ResendOTPResponse = new ResendOTPResponse();

  loginRemainingAttemptCount?: number | null = null;
  twoFactorReason: string = null;

  modalTitle: string;
  modalContent: string;

  // processing status flags
  loggingIn: boolean = false;
  sendingOTP: boolean = false;
  verifyingOTP: boolean = false;
  resettingPassword: boolean = false;
  resendingOTP: boolean = false;

  OTP: string = '';
  rememberMeOpted: boolean = false;
  sub$: Subscription;
  loginPreference: LoginPreference = null;

  constructor(
    public loginService: LoginService,
    public storageservice: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    public renderer: Renderer2,
    public headerTitleService: HeaderTitleService,
  ) {
    if(this.activatedRoute?.snapshot?.url[0]?.path == 'reset-password' || this.router?.url?.split("?")[0] == '/reset-password'){
      this.sub$ = this.activatedRoute.queryParams.subscribe(params => {
        var email = params['email'];
        var token = params['token'];
        var validToken = token.replaceAll(' ', '+',);
        
        if(email && token){
          this.showResetPasswordScreen(email, validToken);
        }
        else{
          this.showInvalidLinkScreen();
        }
      });
    }
    else{
      this.enableRememberMe();
      this.configureModal();

      this.showLoginScreen();
    }
  }
  
  ngOnDestroy(): void {
    this.sub$?.unsubscribe();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() { 
    this.loginPreference =  this.storageservice.getLoginPreference();
    // var hasValidLoginPreference = false;
    // if(this.loginPreference && this.loginPreference?.createdAt){
    //   hasValidLoginPreference = new Date(this.loginPreference.createdAt) >= new Date();
    // }

    if(this.loginPreference){
      this.loginRequest = {
        deviceToken: null,
        email: this.loginPreference?.email,
        password: '',
        rememberMe: true
      }
      setTimeout(() => {   
        this.loginRequest = {
          deviceToken: null,
          email: this.loginPreference?.email,
          password: null,
          rememberMe: true
        }
      }, 2000);
    }
    else{
      this.loginRequest = {
        deviceToken: null,
        email: '',
        password: '',
        rememberMe: false
      }
      setTimeout(() => {   
        this.loginRequest = {
          deviceToken: null,
          email: null,
          password: null,
          rememberMe: false
        }        
      }, 2000);
    }    
  }

  enableRememberMe(){
    const rememberMe = localStorage.getItem('rememberMe');
    // if (rememberMe) {
    //   const storage = (rememberMe == "true") ? localStorage : sessionStorage;
    //   const token = storage.getItem('authorizationDataIdToken');
    //   const user = storage.getItem('user');

    //   if (token && user) {
    //     this.loginService.isLoggedIn = true;
    //     this.router.navigate(['/dashboard']); //redirect to landing page
    //   }
    // }
    
    this.storageservice.clear();
    this.storageservice.setRememberMe(rememberMe);
    
    this.loginService.isLoggedIn = false;
  }

  onEmailChange(){
    // if(!this.rememberMeUsers?.length) return;
    // this.loginRequest.rememberMe = this.rememberMeUsers?.includes(this.loginRequest?.email ) ? true : false;
  }

  configureModal(){
    this.config.centered = true;
    this.config.size = 'md';
  }  
  resetAll(){
    this.hidePassword = true;
    this.hideConfirmPassword = true;

    this.loginRequest = new LoginRequest();
    this.sendOTPRequest = new ForgotPasswordSendOTPRequest();
    this.verifyOTPRequest = new ForgotPasswordVerifyOTPRequest();
    this.resetPasswordRequest = new ResetPasswordRequest();
    this.login2FAVerifyOTPRequest = new Login2FAVerifyOTPRequest();
    this.resendOTPRequest = new ResendOTPRequest();

    this.loginRemainingAttemptCount = null;
    this.twoFactorReason = null;

    this.OTP = '';

    this.loggingIn = false;
    this.sendingOTP = false;
    this.verifyingOTP = false;
    this.resettingPassword = false;
    this.resendingOTP = false;

    this.modalTitle = null;
    this.modalContent = null;
  }

  showLoginScreen() {
    this.resetAll();
    this.formId = this.formType.Login;
  }
  onLogin(modalId: any = null) {
    this.loggingIn = true;

    this.loginService.SignIn(this.loginRequest)
      .pipe(catchError((err) => {
        this.loggingIn = false;
        if (err?.error?.message == 'Invalid Username OR Password' && err?.error?.response?.isLocked === false) {
          this.loginRemainingAttemptCount = err?.error?.response?.attemptRemaining;
        }
        else if (err?.error?.message == 'Your account is locked.' && err?.error?.response?.isLocked === true) {
          this.loginRemainingAttemptCount = err?.error?.response?.attemptRemaining;
          // show popup
          this.openAccountLockedModal(modalId);
        }
        return this.handleError(err);
      }))
      .subscribe(res => {
        this.loggingIn = false;
        if (res.isSuccess) {
          this.loginSuccessResponseHandler(this.loginRequest.email, res?.response as LoginResponse);
        }
        else {
          this.toaster.error(res?.message);
        }
      });
  }

  showForgetPasswordScreen(email) {
    this.resetAll();
    this.sendOTPRequest = {
      email: email
    };
    this.formId = this.formType.ForgotPassword_SendOTP;
  }
  onSendOTP(modalId: any = null) {
    this.sendingOTP = true;

    this.loginService.SendOTP(this.sendOTPRequest)
      .pipe(catchError((err) => {
        this.sendingOTP = false;
        if ((err?.error?.response?.allowedForgotPassword ?? false) == false) {
          this.openOnlyAdminAllowedToResetPasswordErrorModal(modalId);
        }
        return this.handleError(err);
      }))
      .subscribe(res => {
        this.sendingOTP = false;
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.showVerifyOTPScreen(this.sendOTPRequest.email, res.response?.otpToken);
        }
        else {
          this.toaster.error(res.message);
        }
      });
  }

  showVerifyOTPScreen(email, otpToken){
    this.resetAll();
    this.verifyOTPRequest = {
      email: email,
      otpToken: otpToken,
      otp: this.OTP
    };
    this.formId = this.formType.ForgotPassword_VerifyOTP;
  }
  onVerifyOTP() {
    document.querySelectorAll('.digit-group input').forEach(el => el.setAttribute('disabled', 'disabled'));
    this.verifyingOTP = true;

    this.verifyOTPRequest.otp = this.OTP;

    this.loginService.VerifyOTP(this.verifyOTPRequest)
      .pipe(catchError((err) => {
        this.verifyingOTP = false;
        this.clearOTP();
        return this.handleError(err)
      }))
      .subscribe(res => {
        this.verifyingOTP = false;
        if (res.isSuccess == true) {
          this.toaster.success(res.message);
          this.showResetPasswordScreen(this.verifyOTPRequest.email, res.response?.resetPasswordToken);
        }
        else {
          this.clearOTP();
          this.toaster.error(res.message);
        }
      });
  }

  showResetPasswordScreen(email, resetPasswordToken){
    this.resetAll();
    this.resetPasswordRequest = {
      email: email,
      resetPasswordToken: resetPasswordToken,
      newPassword: "",
      confirmPassword: ""
    };
    this.formId = this.formType.ForgotPassword_ResetPassword;
  }
  showResetPasswordRequiredScreen(email, resetPasswordToken){
    this.resetAll();
    this.resetPasswordRequest = {
      email: email,
      resetPasswordToken: resetPasswordToken,
      newPassword: "",
      confirmPassword: ""
    };
    this.formId = this.formType.ResetPasswordRequired;
  }
  onResetPassword() {
    this.resettingPassword = true;

    this.loginService.ResetPassword(this.resetPasswordRequest)
      .pipe(catchError((err) => {
        this.resettingPassword = false;
        return this.handleError(err);
      }))
      .subscribe(res => {
        this.resettingPassword = false;
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.showLoginScreen();
        }
        else {
          this.toaster.error(res.message);
        }
      });
  }

  showTwoFactorRequiredScreen(email, loginResponse: LoginResponse) {
    this.resetAll();
    this.login2FAVerifyOTPRequest = {
      email: email,
      login2FAToken: loginResponse?.login2FAToken,
      otp: this.OTP,
      otpToken: loginResponse?.otpToken
    };
    this.rememberMeOpted = loginResponse?.rememberMeOpted ?? false;
    this.formId = this.formType.TwoFactorOTPRequired;
  }  
  onVerifyTwoFactorOTP(modalId: any = null) {
    document.querySelectorAll('.digit-group input').forEach(el => el.setAttribute('disabled', 'disabled'));
    this.verifyingOTP = true;

    this.login2FAVerifyOTPRequest.otp = this.OTP;

    this.loginService.Login2FAVerifyOTP(this.login2FAVerifyOTPRequest)
      .pipe(catchError((err) => {
        this.verifyingOTP = false;
        this.clearOTP();
        return this.handleError(err);
      }))
      .subscribe(res => {
        this.verifyingOTP = false;
        if (res.isSuccess == true) {
          this.toaster.success(res.message);
          this.loginSuccessResponseHandler(this.login2FAVerifyOTPRequest.email, res?.response as LoginResponse);
        }
        else {
          this.clearOTP();
          this.toaster.error(res.message);
        }
      });
  }

  showInvalidLinkScreen(){
    this.resetAll();
    this.formId = this.formType.InvalidLink;
  }

  onResendOTP(otpToken, modalId: any = null) {
    this.resendingOTP = true;
    this.resendOTPRequest.otpToken = otpToken;
    
    this.loginService.ResendOTP(this.resendOTPRequest)
      .pipe(catchError((err) => {
        this.resendingOTP = false;
        // this.resendOTPResponse = err?.error?.response as ResendOTPResponse;
        // if (err?.error?.response?.resendLimitReached) {          
        // }
        return this.handleError(err);
      }))
      .subscribe(res => {
        this.resendingOTP = false;
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.verifyOTPRequest.otpToken = res?.response?.otpToken;
        }
        else {
          this.toaster.error(res.message);
        }
      });
  }
 

  loginSuccessResponseHandler(email, loginResponse?: LoginResponse) {
    if (loginResponse) {
      if (loginResponse?.requiresTwoFactor) {
        //show 2fa otp screen
        this.showTwoFactorRequiredScreen(email, loginResponse);
      }
      else if (loginResponse?.requiresPasswordReset) {
        //show reset password required screen
        this.showResetPasswordRequiredScreen(email, loginResponse.resetPasswordToken);
      }
      else {
        this.headerTitleService.PermissionVM = loginResponse?.permissionVM;
        this.storageservice.setRememberMe((loginResponse?.rememberMeOpted ?? false)? "true" : "false");
        this.storageservice.store('authorizationDataIdToken', loginResponse.accessToken);
        this.storageservice.store('user', loginResponse?.user);
        this.storageservice.store('permissionVM', loginResponse?.permissionVM);

        //store/remove login preference
        if(loginResponse?.rememberMeOpted){          
          this.storageservice.setLoginPreference(email);
        } else {
          this.storageservice.clearLoginPreference();
        }
          
        this.router.navigate(['dashboard']);
      }
    }
  }

  onKeydown(event: any, value: any) {
    const element = this.renderer.selectRootElement(value, true);
    if (event.key === "Enter") {
      element.focus();
    }
  }
  onKeyUp(event: any, forwardtext: any, backwordtext: any) {
    if (event.key === "Backspace") {
      if (this.backspacePressed) {
        this.backspacePressed = false;
        event.target.setAttribute('disabled', 'disabled');
        const element = this.renderer.selectRootElement(backwordtext, true);
        element.select();
        element.focus();
      }
      else {
        if (backwordtext != `#${event.target.id}`) {
          this.backspacePressed = true;
          this.OTP = this.OTP.substring(0, this.OTP.length - 1);
        }
      }
    }
    else if (event.target.value.length > 0) {
      this.OTP += (event.target.value);
      if (forwardtext == `#${event.target.id}`) {
        if (this.OTP.length > 5 && this.formId === this.formType.ForgotPassword_VerifyOTP) {
          this.onVerifyOTP();
        }
      }
      else {
        const element = this.renderer.selectRootElement(forwardtext, true);
        element.removeAttribute('disabled');
        element.focus();
      }
    }
  }

  clearOTP() {
    var a = document.querySelectorAll('.digit-group input');
    a.forEach(el => {
      const element = this.renderer.selectRootElement(`#${el.id}`, true);
      element.removeAttribute('disabled');
      element.value = "";
      element.setAttribute('disabled', 'disabled');
    });
    const firstEl = this.renderer.selectRootElement(`#digit1`, true);
    firstEl.removeAttribute('disabled');
    firstEl.focus();

    this.OTP = '';
  }

  openOnlyAdminAllowedToResetPasswordErrorModal(modalId: any) {
    this.modalTitle = "Error";
    this.modalContent = "Only admins can reset their passwords directly from the login screen. Contact an Admin to trigger the Reset Password.";
    this.modalService.dismissAll();
    this.modalService.open(modalId);
  }
  openAccountLockedModal(modalId: any) {
    this.modalTitle = "Account Locked";
    this.modalContent = "You have reached the maximum number of login attempts. Your account will be temporarily locked to prevent unauthorized use.";
    this.modalService.dismissAll();
    this.modalService.open(modalId);
  }

  changePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  changeConfirmPasswordVisibility() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  private handleError(response: any) {
    if (response?.status === 499) {
      this.toaster.error("Server response time out. Please try again.");
    }
    else if (response?.error?.message === "Input validation failed!") {
      this.toaster.error(response?.error?.errors[0]);
    }
    else if (response?.error?.message === "Sending OTP failed. Please try again.") {
      this.sendingOTP = false;
      document.querySelectorAll('.digit-group input').forEach(el => el.removeAttribute('disabled'));
      document.querySelectorAll('.digit-group input').forEach(el => el.setAttribute('value', ''));
      this.toaster.warning(response?.error?.message);
      this.OTP = '';
    }       
    else {
      this.toaster.error(response?.error?.message);
    }
    return throwError(response);
  }
}