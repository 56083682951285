import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UserListService } from "./user-list.service";
import { User, UserModalModel, AddUserModel, UpdateUserModel, ResetPasswordByAdmin, Department } from "./user-list.model";
import { UserRolesService } from "../user-roles/user-roles.service";
import { UserRole } from "../user-roles/user-roles.model";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { StorageService } from '@services/storage.service';
import { MasterService } from '../../master/master.service';
import { AgencyVM, MastersVM, MasterValueVM } from '../../master/master.model';
import { ResponseModel } from '@models/response.model';
import { HttpStatusCode } from '@angular/common/http';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})

export class UserListComponent implements OnInit, AfterViewInit {

  rows: any[];

  temp = [];

  modalTitle: string;
  modalOperationTitle: string;
  modalData: UserModalModel;
  modalDataLoading: boolean = false;

  isSaving: boolean = false;
  sendingLink: boolean = false;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  isVisiblePasswordSection: boolean = true;
  showSendButton: boolean = true;

  mastersVM = new MastersVM();
  masterAgencyList: AgencyVM[] = [];
  loadedAgencyList: AgencyVM[] = [];
  masterDepartmentList: MasterValueVM[] = [];
  roles: UserRole[] = [];

  addedDepartmentList: Department[] = [];

  //agencies: number[] = [];
  selectedAgencyList: any[] = [];
  selectedAgencyListCopy: any[] = [];
  isAdminRoleSelected: boolean = false;

  isAgencyUsedMessageDisplay: boolean;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent;

  constructor(public service: UserListService,
    private userRolesService: UserRolesService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    public storageService: StorageService,
    public masterService: MasterService,
    private toaster: ToastrService) {
    this.config.centered = true;
  }


  ngOnInit(): void {
    this.loadAgencyData(null);
    this.storageService.store('userManagementTID', 3);
    this.loadListData();
  }

  loadAgencyData(userId) {
    this.masterService.loadAgencyData(userId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        this.loadedAgencyList = res.response as AgencyVM[];
        this.masterAgencyList = [{
          id: 0,
          name: 'All',
          description: '',
          isActive: false,
          isInUsed: false
        }, ...this.loadedAgencyList];
      });

  }

  loadMasters() {
    this.masterService.getMasters()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
        this.masterDepartmentList = this.mastersVM.masterDepartment.masterValues;
      });
  }



  addNewDepartment() {
    this.addedDepartmentList.push({ id: 0, isSupervisor: false, timestamp: Date.now().toString() });
  }
  deleteAddedDepartment(index) {
    this.addedDepartmentList.splice(index, 1);
  }
  isDepartmentSelectable(departmentId): boolean {
    return !!this.addedDepartmentList.find(s => s.id == departmentId);
  }

  ngAfterViewInit(): void {
    this.getRoles();
    this.loadMasters();
  }

  loadListData() {
    this.service.getAllUsers()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.rows = res.response;
        this.temp = res.response;
      });
  }

  getRoles() {
    this.userRolesService.getAllRoles()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.roles = res.response;
      });
  }
  onRoleSelection(event) {
    console.log(this.modalData.userRole);
    //selectedAgencyList

    //this.isAdminRoleSelected = (event == "Admin");
    if (this.modalData.userRole == "Admin") {
      if (event == "Admin") {
        this.isAdminRoleSelected = true;
        //this.selectedAgencyList = this.masterAgencyList.map(s=>s.id);
      }
      else {
        this.isAdminRoleSelected = false;
        this.selectedAgencyList = [];
      }
    }
    else {
      if (event == "Admin") {
        this.isAdminRoleSelected = true;
        this.selectedAgencyList = this.masterAgencyList.map(s => s.id);
      }
      else {
        this.isAdminRoleSelected = false;
        // this.selectedAgencyList = [];
      }
    }
  }

  private handleError(error: any) {
    this.isSaving = false;
    if (error.error || error.error.errors) {
      this.toaster.error(error.error.errors[0]);
    }
    else if (error.status === HttpStatusCode.Forbidden) {
    }
    else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  openResetPasswordModal(modalId1: any, resetModel: User = null) {
    this.modalTitle = "Reset Password";
    this.config.size = 'sm';
    this.isSaving = false;
    this.modalService.dismissAll();
    this.modalData = new UserModalModel();
    this.hidePassword = true;
    this.hideConfirmPassword = true;
    if (resetModel !== null) {
      this.modalTitle = "Reset Password";
      this.modalData.userId = resetModel.userId;
      this.modalData.firstName = resetModel.firstName;
      this.modalData.lastName = resetModel.lastName;
      this.modalData.email = resetModel.email;
      this.modalData.password = "";
      this.modalData.confirmPassword = "";
      this.modalData.userRole = resetModel.userRole;
      this.modalData.isActive = resetModel.isActive;
    }
    this.modalService.open(modalId1);
  }

  openModal(modalId: any, editModel: User = null) {
    this.isAgencyUsedMessageDisplay = false;
    this.selectedAgencyList = [];
    this.addedDepartmentList = [];

    this.isSaving = false;
    this.modalService.dismissAll();
    this.config.size = 'md';
    this.modalData = new UserModalModel();
    this.hidePassword = true;
    this.hideConfirmPassword = true;

    if (editModel !== null) {
      this.loadAgencyData(editModel.userId);
      this.modalDataLoading = true;
      this.service.getMappingData(editModel.userId)
        .pipe(catchError((err) => {
          this.modalDataLoading = false;
          return this.handleError(err);
        }))
        .subscribe(res => {
          this.modalDataLoading = false;
          this.selectedAgencyList = ((editModel.userRole == 'Admin' && res?.response?.agencies?.length == 0) ? this.masterAgencyList.map(s => s.id) : res?.response?.agencies ?? []) as any[];
          this.selectedAgencyListCopy = this.selectedAgencyList;
          this.isAdminRoleSelected = (editModel.userRole == 'Admin');
          if (res.response.departments) {
            const departments = res.response.departments as Department[];
            departments.forEach((d, i) => {
              this.addedDepartmentList.push({
                id: d.id,
                isSupervisor: d.isSupervisor,
                timestamp: Date.now().toString() + i
              });
            });
          }
        });
      this.isVisiblePasswordSection = false;
      this.modalTitle = "Edit User";
      this.modalData.userId = editModel.userId;
      this.modalData.firstName = editModel.firstName;
      this.modalData.lastName = editModel.lastName;
      this.modalData.email = editModel.email;
      this.modalData.password = "";
      this.modalData.confirmPassword = "";
      this.modalData.userRole = editModel.userRole;
      this.modalData.isActive = editModel.isActive;
      this.modalData.isEditAction = true;
    }
    else {
      this.modalTitle = "Create New User";
      this.isVisiblePasswordSection = true;
      this.addNewDepartment();
    }
    this.modalService.open(modalId);
  }

  saveModalData(modalData: UserModalModel, modal: any, value: string) {
    this.isSaving = true;
    this.addedDepartmentList = this.addedDepartmentList.filter(a => a.id != 0)
    // Add
    if (modalData.userId == null) {
      let model: AddUserModel = {
        firstName: modalData.firstName,
        lastName: modalData.lastName,
        email: modalData.email,
        password: modalData.password,
        confirmPassword: modalData.confirmPassword,
        userRole: modalData.userRole,
        agencies: (this.selectedAgencyList.length == this.masterAgencyList.length) ? [0] : this.selectedAgencyList,
        departments: this.addedDepartmentList
      };

      this.service.registerUser(model)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess) {
            modal.close();
            this.loadListData();
            this.toaster.success(res.message);
          }
          else {
            this.toaster.error(res.message);
          }
          this.isSaving = false;
        });
    }
    else if (value == "ResetPassword") {
      let ResetPasswordmodel: ResetPasswordByAdmin = {
        userId: modalData.userId,
        password: modalData.password,
        confirmPassword: modalData.confirmPassword
      };
      this.service.resetPasswordUser(ResetPasswordmodel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess) {
            modal.close();
            this.loadListData();
            this.toaster.success(res.message);
          }
          else {
            this.toaster.error(res.message);
          }
          this.isSaving = false;
        });
    }
    // Update
    else {
      let model: UpdateUserModel = {
        firstName: modalData.firstName,
        lastName: modalData.lastName,
        email: modalData.email,
        password: modalData.password,
        confirmPassword: modalData.confirmPassword,
        userRole: modalData.userRole,
        isActive: modalData.isActive,
        agencies: (this.selectedAgencyList.length == this.masterAgencyList.length) ? [0] : this.selectedAgencyList,
        departments: this.addedDepartmentList,
      };
      this.service.updateUser(modalData.userId, model)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess) {
            modal.close();
            this.loadListData();
            this.toaster.success(res.message);
          }
          else {
            this.toaster.error(res.message);
          }
          this.isSaving = false;
        });
    }
  }

  public filter(itemList: UserRole[]): UserRole[] {
    return itemList?.filter(a => a.isActive);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (index) {
      return (index.email.toLowerCase().indexOf(val) !== -1 ||
        index.firstName.toLowerCase().indexOf(val) !== -1 ||
        index.lastName.toLowerCase().indexOf(val) !== -1 ||
        index.userRole.toLowerCase().indexOf(val) !== -1 ||
        !val);
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  shareCheckedList(item: any) {
    this.selectedAgencyList = [];
    if (!!item && item.length < this.masterAgencyList.length && item[0] == 0) {
      item.splice(0, 1);
    }
    this.selectedAgencyList = item;
    this.selectedAgencyListCopy = item;
  }

  shareIndividualCheckedList(item: {}) {
    console.log(item);
  }

  openInitiateResetPasswordModal(modalId1: any, resetModel: User = null) {
    this.showSendButton = true;
    this.modalTitle = "Initiate Password Reset?";
    this.modalOperationTitle = "A reset password link will be sent to the registered email address.";
    this.config.size = 'md';
    this.modalService.dismissAll();
    this.modalService.open(modalId1);
  }

  sendMailForResetPassword(modalData: UserModalModel, modalId) {
    this.sendingLink = true;
    this.service.sendResetPasswordLink({ userId: modalData.userId })
      .pipe(catchError((err) => {
        this.sendingLink = false;
        return this.handleError(err);
      }))
      .subscribe(res => {
        this.sendingLink = false;
        if (res.isSuccess) {
          this.showSendButton = false;
          this.modalTitle = "Reset Password";
          this.modalOperationTitle = "A link was sent to the registered email address.";
          this.toaster.success(res.message);

          this.modalService.dismissAll();
          this.modalService.open(modalId);
        }
        else {
          this.toaster.error(res.message);
        }
      });

    // if(value == "ResetPassword"){
    //   this.showSendButton = false;
    //   this.modalTitle = "Reset Password";
    //   this.modalOperationTitle = "A link was sent to the registered email address.";
    //   this.config.size = 'md';
    //   this.modalService.dismissAll();
    //   this.modalService.open(modal);
    // }
  }

}
