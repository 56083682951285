<div class="container-fluid login-page p-xl-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <div class="logo">
              <img class="img-fluid for-light p-1" src="../assets/images/logo/ger_logo.png" alt="login" width="180"
                height="180">
              <img class="img-fluid for-dark p-1" src="../assets/images/logo/ger_logo.png" alt="login" width="180"
                height="180">
            </div>
          </div>
          <div class="login-main px-md-5 px-4 py-4" *ngIf="formId==formType.Login">
            <form class="theme-form needs-validation px-md-3 px-1 py-2">
              <!-- <div class="form-header">
              </div> -->
              <!-- <div class="form-error" *ngIf="!loggingIn && errorMessage">
              </div> -->
              <div class="form-content">
                <div class="form-group">
                  <label class="col-form-label">
                    Email Address
                  </label>
                  <input class="form-control" type="email" name="email" [(ngModel)]="loginRequest.email" required id="username"
                    autocomplete="off" placeholder="sample@example.com" (keydown)="onKeydown($event,'#password')" (ngModelChange)="onEmailChange()">
                </div>
                <div class="form-group">
                  <label class="col-form-label">
                    Password
                    <div class="password-showhide-control mt-0">
                      <input [type]="hidePassword ? 'password' : 'text'" name="password" id="password"
                        class="form-control" [(ngModel)]="loginRequest.password" placeholder="********"
                        autocomplete="off" required (keydown)="onKeydown($event,'#submitButton')">
                      <button class="btn-showHide color-blue"
                        (click)="changePasswordVisibility()">{{hidePassword?"show":"hide"}}</button>
                    </div>
                  </label>
                </div>
                <div class="form-group mb-2">
                  <div class="d-flex align-items-center">
                    <span class="theme-font-color-red" *ngIf="loginRemainingAttemptCount">Attempts
                      Left:{{loginRemainingAttemptCount}}</span>
                    <span class="link-blue ml-auto" (click)="showForgetPasswordScreen(loginRequest.email)">Forgot password?</span>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label text-muted d-flex align-items-center" for="checkbox1">
                    <input id="checkbox1" name="rememberMe" class="mr-1 bg-dark" [(ngModel)]="loginRequest.rememberMe"
                      [checked]="loginRequest.rememberMe" type="checkbox">
                    <span class="roboto lh-normal">Remember Me: This is a trusted device.</span>
                  </label>
                </div>
              </div>
              <div class="form-footer mt-4">
                <button id="submitButton" (click)="onLogin(ErrorModal)" class="btn btn-submit" type="submit" [ngClass]="{
                  'processing': loggingIn}">
                  <span>Sign in</span>
                </button>
              </div>
            </form>
          </div>

          <!-- Forgot password view -->
          <div class="login-main px-md-5 px-4 py-4" *ngIf="formId==formType.ForgotPassword_SendOTP">
            <form class="theme-form needs-validation px-md-3 px-1 py-2">
              <div class="form-header">
                <h1 class="title">Reset your Password</h1>
              </div>
              <div class="form-content mt-3">
                <div class="form-group py-4">
                  <label class="col-form-label">
                    Enter Your Email Address:
                  </label>
                  <input class="form-control" type="email" name="email" [(ngModel)]="sendOTPRequest.email" required
                    placeholder="sample@example.com">
                </div>
              </div>
              <div class="form-footer mt-3">
                <button (click)="onSendOTP(ErrorModal)" class="btn btn-submit" type="submit" [ngClass]="{
                  'processing': sendingOTP}">
                  <span>Send OTP</span>
                </button>
                <div class="text-center mt-2">Already have a password?
                  <span class="link-blue underline" (click)="showLoginScreen()">Sign in</span>
                </div>
              </div>
            </form>
          </div>

          <div class="login-main px-md-5 px-4 py-4" *ngIf="formId==formType.ForgotPassword_VerifyOTP">
            <form class="theme-form needs-validation px-md-3 px-1 py-2">
              <div class="form-header">
                <h1 class="title">Reset your Password</h1>
              </div>
              <div class="form-content mt-3">
                <div class="form-group pt-4">
                  <label class="col-form-label">
                    Enter OTP<span class="theme-font-color-red">*</span>
                  </label>
                  <div class="digit-group">
                    <input class="input" type="text" id="digit1" name="digit1"
                      (keyup)="onKeyUp($event,'#digit2','#digit1')" maxlength="1" autofocus autocomplete="off" required
                      onlyNumbers>
                    <input class="input" type="text" id="digit2" name="digit2"
                      (keyup)="onKeyUp($event,'#digit3','#digit1')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit3" name="digit3"
                      (keyup)="onKeyUp($event,'#digit4','#digit2')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit4" name="digit4"
                      (keyup)="onKeyUp($event,'#digit5','#digit3')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit5" name="digit5"
                      (keyup)="onKeyUp($event,'#digit6','#digit4')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit6" name="digit6"
                      (keyup)="onKeyUp($event,'#digit6','#digit5')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="mt-2" [ngClass]="{'processing': resendingOTP}">
                    Didn&rsquo;t receive OTP?
                    <span class="link-blue underline" (click)="onResendOTP(verifyOTPRequest.otpToken, ErrorModal)">Resend OTP</span>
                  </div>
                </div>
                <div *ngIf="verifyingOTP">
                  <div class="text-center mt-4">
                    <span>Verifing OTP. Please wait...</span>
                  </div>
                </div>
              </div>
              <div class="form-footer mt-3">
                <div class="text-center mt-2">Already have a password?
                  <span class="link-blue underline" (click)="showLoginScreen()">Sign in</span>
                </div>
              </div>
            </form>
          </div>

          <div class="login-main px-md-5 px-4 py-4" *ngIf="formId==formType.ForgotPassword_ResetPassword">
            <form class="theme-form needs-validation px-md-3 px-1 py-2">
              <div class="form-header">
                <h1 class="title">Reset your Password</h1>
                <p class="sub-title text-blurish-grey mt-4 mb-2">
                  Passwords must be minimum of 12 characters and must not be similar to last 5 passwords.
                </p>
              </div>
              <div class="form-content mt-3">
                <div class="form-group">
                  <label class="col-form-label">
                    New Password<span class="theme-font-color-red">*</span>
                    <div class="password-showhide-control mt-0">
                      <input [type]="hidePassword ? 'password' : 'text'" name="newPassword" class="form-control"
                        [(ngModel)]="resetPasswordRequest.newPassword" required>
                      <button class="btn-showHide color-blue"
                        (click)="changePasswordVisibility()">{{hidePassword?"show":"hide"}}</button>
                    </div>
                  </label>
                </div>
                <div class="form-group">
                  <label class="col-form-label">
                    Confirm Password<span class="theme-font-color-red">*</span>
                    <div class="password-showhide-control mt-0">
                      <input [type]="hideConfirmPassword ? 'password' : 'text'" name="confirmPassword"
                        class="form-control" [(ngModel)]="resetPasswordRequest.confirmPassword" required>
                      <button class="btn-showHide color-blue"
                        (click)="changeConfirmPasswordVisibility()">{{hideConfirmPassword?"show":"hide"}}</button>
                    </div>
                  </label>
                </div>
              </div>
              <div class="form-footer mt-4">
                <button (click)="onResetPassword()" class="btn btn-submit" type="submit" [ngClass]="{
                  'processing': resettingPassword}">
                  <span>Update Password</span>
                </button>
                <div class="text-center mt-4">Already have a password?
                  <span class="link-blue underline" [routerLink]="['/auth/login']">Sign in</span>
                </div>
              </div>
            </form>
          </div>
          <!-- Forgot password view -->

          <div class="login-main px-md-5 px-4 py-4" *ngIf="formId==formType.TwoFactorOTPRequired">
            <form class="theme-form needs-validation px-md-3 px-1 py-2">
              <div class="form-header">
                <h2 class="title">Verification</h2>
                <p class="sub-title text-center mt-4 mb-2">
                  {{rememberMeOpted?"We do not recognize this device. ":""}}Enter the verification code sent to your email address
                  <span class="color-blue" [ngClass]="{'d-block': !rememberMeOpted}" >{{login2FAVerifyOTPRequest.email}}</span>
                </p>
              </div>
              <div class="form-content mt-3">
                <div class="form-group pt-2">
                  <label class="col-form-label">
                    Enter OTP<span class="theme-font-color-red">*</span>
                  </label>
                  <div class="digit-group">
                    <input class="input" type="text" id="digit1" name="digit1"
                      (keyup)="onKeyUp($event,'#digit2','#digit1')" maxlength="1" autofocus autocomplete="off" required
                      onlyNumbers>
                    <input class="input" type="text" id="digit2" name="digit2"
                      (keyup)="onKeyUp($event,'#digit3','#digit1')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit3" name="digit3"
                      (keyup)="onKeyUp($event,'#digit4','#digit2')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit4" name="digit4"
                      (keyup)="onKeyUp($event,'#digit5','#digit3')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit5" name="digit5"
                      (keyup)="onKeyUp($event,'#digit6','#digit4')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit6" name="digit6"
                      (keyup)="onKeyUp($event,'#digit6','#digit5')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="mt-2" [ngClass]="{'processing': resendingOTP}">
                    Didn&rsquo;t receive OTP?
                    <span class="link-blue underline" (click)="onResendOTP(login2FAVerifyOTPRequest.otpToken, ErrorModal)">Resend OTP</span>
                  </div>
                </div>
                <div *ngIf="verifyingOTP">
                  <div class="text-center mt-4">
                    <span>Verifing OTP. Please wait...</span>
                  </div>
                </div>
              </div>
              <div class="form-footer mt-3">
                <button (click)="onVerifyTwoFactorOTP(ErrorModal)" class="btn btn-submit" [ngClass]="{
                  'processing': verifyingOTP}">
                  <span>Confirm</span>
                </button>
              </div>
            </form>
          </div>

          <div class="login-main px-md-5 px-4 py-4" *ngIf="formId==formType.ResetPasswordRequired">
            <form class="theme-form needs-validation px-md-3 px-1 py-2">
              <div class="form-header">
                <h1 class="title">Reset your Password</h1>
                <p class="sub-title text-blurish-grey mt-4 mb-2">
                  Passwords must be minimum of 12 characters and must not be similar to last 5 passwords.
                </p>
              </div>
              <div class="form-content mt-3">
                <div class="form-group">
                  <label class="col-form-label">
                    New Password<span class="theme-font-color-red">*</span>
                    <div class="password-showhide-control mt-0">
                      <input [type]="hidePassword ? 'password' : 'text'" name="newPassword" class="form-control"
                        [(ngModel)]="resetPasswordRequest.newPassword" required>
                      <button class="btn-showHide color-blue"
                        (click)="changePasswordVisibility()">{{hidePassword?"show":"hide"}}</button>
                    </div>
                  </label>
                </div>
                <div class="form-group">
                  <label class="col-form-label">
                    Confirm Password<span class="theme-font-color-red">*</span>
                    <div class="password-showhide-control mt-0">
                      <input [type]="hideConfirmPassword ? 'password' : 'text'" name="confirmPassword"
                        class="form-control" [(ngModel)]="resetPasswordRequest.confirmPassword" required>
                      <button class="btn-showHide color-blue"
                        (click)="changeConfirmPasswordVisibility()">{{hideConfirmPassword?"show":"hide"}}</button>
                    </div>
                  </label>
                </div>
              </div>
              <div class="form-footer mt-4">
                <button (click)="onResetPassword()" class="btn btn-submit" type="submit" [ngClass]="{
                  'processing': resettingPassword}">
                  <span>Update Password</span>
                </button>
                <!-- <div class="text-center mt-4">Already have a password?
                  <span class="link-blue underline" (click)="showLoginScreen()">Sign in</span>
                </div> -->
              </div>
            </form>
          </div>

          <div class="login-main" *ngIf="formId==formType.InvalidLink">
            <form class="theme-form needs-validation">
              <!-- <button class="pop-up-btn-position" (click)="ngOnInit()" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
                  <path
                    d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
                    transform="translate(0 -0.001)" fill="#a1a1a1" />
                </svg>
              </button> -->
              <span class="popup-title">Invalid Link</span>
              <div class="middle-text pt-3">
                <span>Please contact an application administrator</span>
                <span>to reset Your password</span>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Errors Pop-UP  -->
<ng-template #ErrorModal let-modal>
  <div class="d-flex justify-content-end w-100">
    <svg class="cursor-pointer" (click)="modal.dismiss()" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
      viewBox="0 0 22.64 22.64">
      <path
        d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
        transform="translate(0 -0.001)" fill="#a1a1a1" />
    </svg>
  </div>
  <div class="modal-body px-3 px-lg-5">
    <div class="text-center theme-font-color-red password-text f-16 f-w-600 mb-2"><span
        class="underline">{{modalTitle}}</span></div>
    <div class="text-center theme-font-color-red password-text f-16 my-3">{{modalContent}}
    </div>
  </div>
</ng-template>
<!--Errors Pop-UP  -->