<div>
  <div class="ngxdt user-table">

    <div class="ngxdt-header">
      <div class="ngxdt-search">
        <div class="ngxdt-search-wrapper">
          <i class="fa fa-search"></i>
          <!-- <input type="text" placeholder="Search"> -->
          <input type="text" placeholder="Search" (keyup)="search($event)">
          <button class="btn-search">
            <i class="fa fa-filter"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="ngxdt-body">
      <ngx-datatable #table class="bootstrap expandable user-permissions-table" [footerHeight]="50" [rows]="rows" [groupRowsBy]="'category'"
        [groupExpansionDefault]="true" [columnMode]="'force'" [count]="rows?.length" [limit]="rows?.length">

        <!-- Group Header Template -->
        <ngx-datatable-group-header #groupHeader>
          <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
            <div class="group-header-wrapper">
              <div class="index-column">{{getGroupIndex(group)}}.</div>
              <div class="group-column" [class.collapse-icon-down]="!expanded" [class.collapse-icon-up]="expanded"
                title="{{group.value[0].category}}" (click)="toggleExpandGroup(group)">
                <strong>{{ group.value[0].category }} ({{group.value.length}})</strong>
              </div>
              <div *ngFor="let role of permissionModel?.availableCustomerRoles" class="checkbox-column">
                <div class="checkbox checkbox-custom"
                  [class.checkbox-dash]="getCheckboxValue(group.value, role) == checkboxValue.partial">
                  <input type="checkbox" id="checkbox_{{group.key}}_{{role.id}}"
                    [checked]="getCheckboxValue(group.value, role) == checkboxValue.all" [disabled]="isAdmin(role.name)"
                    (click)="updatePermissionGroup(group.value,role,$event)">
                  <label for="checkbox_{{group.key}}_{{role.id}}"></label>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-group-header>

        <!-- Row Column Template -->
        <ngx-datatable-column [width]="70" [canAutoResize]="false" headerClass="make-center index-column"
          cellClass="make-center index-column" name="#" [sortable]="false" [draggable]="false" [resizeable]="false">
          <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
            <span style="display: none;">{{rowIndex}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Permissions" [width]="270" [canAutoResize]="false" [sortable]="false"
          [draggable]="false" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div>
              <img src="assets/images/svg/dash.svg" alt="-">
              <span class="ml-3">{{ row.name }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column headerClass="make-center" cellClass="make-center" [width]="120" [canAutoResize]="false"
          *ngFor="let role of permissionModel?.availableCustomerRoles" [sortable]="false" [draggable]="false"
          [resizeable]="false">
          <ng-template ngx-datatable-header-template>
            <span>{{role.name}}</span>
          </ng-template>
          <ng-template let-group="group" let-rowIndex="rowIndex" let-row="row" let-value="value"
            ngx-datatable-cell-template>
            <div class="checkbox checkbox-custom">
              <input type="checkbox" id="checkbox_{{row.id}}_{{role.id}}" [checked]="hasPermission(row.id, role.id)"
                [disabled]="isAdmin(role.name)" (click)="updatePermission(row,role,$event)">
              <label for="checkbox_{{row.id}}_{{role.id}}"></label>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>