import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../../shared/models/response.model';
import { DataService } from '../../../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserListService {

  constructor(private service: DataService) { }

  //get all
  public getAllUsers(search: string = ""): Observable<ResponseModel> { 
    let url = `/identityapi/User/GetUsers`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //add
  public registerUser(data: any): Observable<ResponseModel> { 
    let url = `/identityapi/User/register`;
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //edit
  public updateUser(userId: string, data: any): Observable<ResponseModel> { 
    let url = `/identityapi/user/update/${userId}`;
    var myJson = JSON.stringify(data);
    return this.service.putWithId(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  //Reset Password
  public resetPasswordUser(data: any): Observable<ResponseModel> { 
    let url = `/identityapi/User/ResetPasswordByAdmin`;
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //send Reset Password link
  public sendResetPasswordLink(data: any): Observable<ResponseModel> { 
    let url = `/identityapi/User/sendResetPasswordLink`;
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // Get mapping Details
  public getMappingData(userId:string): Observable<ResponseModel> { 
    let url = `/gerapi/user/getMappingDetails/${userId}`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }


}
