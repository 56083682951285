import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Observable } from 'rxjs/Rx';
import { catchError, tap } from 'rxjs/operators';
import { SecurityService } from './security.service';
import { ToastrService } from 'ngx-toastr';
//service
import { Router } from '@angular/router';
import { baseUrl } from 'src/environments/environment';
import { StorageService } from './storage.service';

declare var require;
const Swal = require('sweetalert2');

// Implementing a Retry-Circuit breaker policy 
// is pending to do for the SPA app
@Injectable({
    providedIn: 'root'
})
export class DataService {
    constructor(
        private http: HttpClient,
        private securityService: SecurityService,
        private router: Router,
        private toaster: ToastrService,
        public storageService: StorageService
    ) { }

    get(url: string, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doGet(baseUrl + url, isAuthTokenRequired, params, isEnableLoader);
    }

    post(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPost(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
    }

    postWithId(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPost(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
    }

    putWithId(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPut(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
    }

    upload(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<any> {
        return this.doUpload(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
    }

    delete(url: string, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<Response> {
        return this.doDelete(baseUrl + url, isAuthTokenRequired, params, isEnableLoader);
    }

    private doUpload(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<any> {

        let headers = new HttpHeaders();
        headers = this.securityService.setHeadersToUpload(isAuthTokenRequired).headers;

        return this.http.post(url, data, { headers: headers, reportProgress: true, observe: 'events' })
            .pipe(
                tap((res: HttpEvent<Object>) => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    private doGet(url: string, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {

        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

        return this.http.get(url, httpOptions)
            .pipe(
                tap((res: Response) => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    private doPost(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {

        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

        return this.http.post(url, data, httpOptions)
            .pipe(
                tap((res: Response) => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    private doDelete(url: string, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {

        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

        return this.http.delete(url, httpOptions)
            .pipe(
                tap((res: Response) => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    private doPut(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {

        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

        return this.http.put(url, data, httpOptions)
            .pipe(
                tap((res: Response) => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: any) {
        if (error.error instanceof ErrorEvent) {
            
        }
        else if (error.status === 401) {
            this.router.navigate(['/auth/login']);
        }
        else if (error.status === HttpStatusCode.Forbidden) {
         //do logic for the 403 Forbidden
        //  Swal.fire({
        //         type: 'Forbidden',
        //         text: `${error.error}`
        //     });

        // return;
        //  this.toaster.error(error.error);
        //  return Observable.throwError(error);
        }
        // else if(error.status===400){
        //     this.toaster.error(error.error.errors[0].toString());
        // }
        // else {
        //     //this.toaster.success('error.error.message');
        //     Swal.fire({
        //         type: 'error',
        //         title: 'Oops...',
        //         text: `message: ${error.error.message}`
        //     });
        //     // console.error('Backend - ' +
        //     //     `status: ${error.status}, ` +
        //     //     `statusText: ${error.statusText}, ` +
        //     //     `message: ${error.error.message}`);
        //     console.log(`API Response:  \n Status: ${error.status},
        //                                 \n statusText: ${error.statusText}
        //                                 \n message: ${error.message}`);
        // }

        // return an observable with a user-facing error message
        return Observable.throwError(error || 'server error');
    }

}
